<template>
  <div style="width: 100%;height:auto;display: flex;justify-content: center">
    <div style="width: 100%;height: 100px;background:#FFFFFF;position: fixed; top: 0;z-index: 999;display: flex;justify-content: center">
      <div style="width: 1500px;height: 100px;display: flex;align-items: center;justify-content: space-between" >
        <div style="display: flex;align-items: center;">
          <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/bai_log.png" style="height: 46px;width: auto;margin: 0 10px;"/>
          <div style="color: #031F88;font-size: 30px;font-weight: bold"> | 搜索</div>

          <el-input placeholder="请输入内容" v-model="content" class="input-with-select" style="width: 600px;margin-left: 40px" @change="inputChange"  >
            <div class="hand op sc" style="width: 60px; text-align: center" slot="append">
              <img style="width: 20px; height: 20px;" src="../room/imgs/searchIcon.png">
            </div>
          </el-input>
        </div>

        <div style="display: flex;align-items: center;">
          <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/icon.png" style="height: 16px;width: auto"/>

          <img :src="user.headIco" style="height: 26px;width: 26px;margin: 0 10px 0 40px;border-radius: 50%;"/>

          <span style="margin-right: 10px;">{{user.nickName}}</span>

          <i class="el-icon-caret-bottom"></i>

        </div>


      </div>
    </div>

    <div style="width: 1500px;min-height:600px;">
      <div style="width: 280px; position: fixed; top: 108px;height: 600px;">
        <div style="width: 92%;margin-bottom: 1px;padding: 8px 4%;background: #fff; overflow: hidden; font-weight: 600; box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600)">
          <el-menu :default-active="activeName" style="width:100%; overflow: hidden;"  @select="handleClick">
            <el-menu-item :index="item.name" style="height: 46px; line-height: 42px; margin-bottom: 2px" v-for="(item,index) in active_list" v-bind:key="index">
              <template>
                <div style="display: flex;justify-content:center;">
                  <span style="font-size: 16px; margin-top: 2px;text-align: center">{{ item.label }}</span>
                  <div style="width: 20px; height: 20px; margin-left: 10px;" v-if="activeName == item.name">
                    <i class="el-icon-caret-right"></i>
                  </div>
                </div>
              </template>
            </el-menu-item>
          </el-menu>
        </div>
      </div>

      <div style="width: 900px;float:left;margin-left: 290px;margin-top: 108px;">

        <!-- room -->
        <div v-if="activeName == 'room' " style="background: #ffffff">
          <div v-for="(item,index) in dataLiat" v-bind:key="index" style="width: 100%;height: auto;">
            <template>
              <ContentALL :data="item"></ContentALL>
            </template>
          </div>
        </div>

        <!-- circle -->
        <div v-if="activeName == 'circle' " style="background: #FFFFFF">
          <div v-for="(item,index) in dataLiat" v-bind:key="index" style="width: 100%;height: auto;">
            <template>
              <circleContentALL :data="item"></circleContentALL>
            </template>
          </div>
        </div>

        <!-- 知识仓库 -->
        <div v-if="activeName == 'knowledge' " >

          <!--  分类选择 -->
          <div style="width: 100%;display: flex;text-align: center;background: #FFFFFF;padding: 10px 20px ;margin-bottom: 10px;box-sizing: border-box">
            <div v-for="(item,index) in typeList" :key="index" style="">
              <div style="width:90px;height: 35px;display: flex;justify-content: center;align-items:center;font-size: 16px;cursor: pointer;" @click="chooseType(item.value)" :class="{ Fcti: item.value === type }">{{item.name}}</div>
            </div>
          </div>

          <!--  图片 或者 视频 -->
          <div style="overflow:hidden;padding-left: 20px;background: #FFFFFF" v-if="type == 'img' || type == 'video'">
            <div v-for="(item,index) in dataLiat" :key="index" style="width:calc((100% - 60px ) / 3); float: left;margin-right: 20px;margin-bottom: 20px;box-shadow: 0px 2px 8px 1px rgba(0,0,0,0.16);border: 1px solid #E4E4E4;box-sizing: border-box">
              <div style="width: 100%;height:100% ;" >
                <div  style="width: 100%; height: 60%;overflow: hidden;cursor: pointer;">
                  <img :src="item.cover" style="width:100%;height:229px;object-fit: cover;">
                </div>
                <div style="width: 90%;color: #1a1a1a;height:10%;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;padding: 10px 5% 5px 5%;cursor: pointer;">{{item.title}}</div>
                <div style="margin-left: 5%;float: left"><img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/menu/hongbao/icon12.png" style="width: 20px;height: 14px;"></div>
                <div style="font-size: 16px;float: left;color:#999999;margin:-1px  5px">{{ item.clickCount }}</div>
                <div style="float: right;color: #999999;font-size: 16px;margin-right: 15px">{{item.createTime}}</div>

                <div style="border-bottom: 1px solid #CECFD0;float: left;width: 100%;padding-top: 10px;"></div>
                <div v-if=" typeof item.user == 'undefined'" style="overflow: auto">
                  <div style="width: 26px;height: 26px;float: left;margin: 16px 0 0 20px ">
                    <img :src="item.headIco" style="width: 100%;height: 100%;border-radius: 50%;">
                  </div>
                  <div style="font-size: 17px;color: #444444;float: left;margin: 19px 5px;">{{ item.nickName }}</div>
                </div>
                <div v-else>
                  <div style="width: 26px;height: 26px;float: left;margin: 16px 0 0 20px ">
                    <img :src="item.user.headIco" style="width: 100%;height: 100%;border-radius: 50%;">
                  </div>
                  <div style="font-size: 17px;color: #444444;float: left;margin: 19px 5px;">{{ item.user.nickName }}</div>
                </div>
                <div v-if="item.isCollection == 0" >
                  <div style="float: right;color: #666666;cursor: pointer;margin:20px 15px 0 5px;">收藏</div>
                  <div style="float: right;margin-top: 20px "> <svg t="1669371301358" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2679" width="20" height="20"><path d="M512 720.416l232.096 171.904a19.2 19.2 0 0 0 16.864 2.88c9.536-2.88 14.592-12.224 11.84-20.864l-88.96-281.6 237.344-176.96a16.48 16.48 0 0 0 6.816-13.152c0-9.152-7.904-16.928-18.112-16.96l-292-0.448-88.48-277.12a17.568 17.568 0 0 0-11.776-11.264c-9.856-2.976-20.16 2.304-23.04 11.264l-88.48 277.12-292 0.48a18.624 18.624 0 0 0-14.624 6.944 16.096 16.096 0 0 0 3.328 23.136l237.376 176.96-88.992 281.6a15.904 15.904 0 0 0 2.72 14.688c6.048 7.744 17.856 9.312 25.984 3.296L512 720.416z m-194.016 223.36a83.008 83.008 0 0 1-114.56-15.424 79.904 79.904 0 0 1-13.28-73.28l75.296-238.24-200.864-149.76a80.096 80.096 0 0 1-15.424-113.92 82.624 82.624 0 0 1 64.864-31.456l245.312-0.384 74.304-232.672c13.6-42.56 59.52-66.112 102.56-53.024A81.536 81.536 0 0 1 590.4 88.64l74.304 232.64 245.312 0.416c45.184 0.064 82.08 36.16 82.016 81.024a80.48 80.48 0 0 1-32.576 64.352l-200.864 149.76 75.296 238.24c13.568 42.976-11.072 88.352-54.496 101.408a83.2 83.2 0 0 1-73.344-12.736L512 800.064l-194.016 143.68z" p-id="2680" fill="#8a8a8a"></path></svg></div>
                </div>
                <div v-else>
                  <div style="float: right;color: #666666;cursor: pointer;margin:20px 15px 0 5px;">已收藏</div>
                  <div style="float: right;margin-top: 20px "> <svg t="1697536235932" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4049" width="20" height="20"><path d="M957.111079 403.480424c-3.927449-11.669784-14.447037-19.86442-26.723641-20.816095L647.68068 360.749204 540.183388 84.126003c-4.586458-11.803837-15.951297-19.579941-28.614711-19.579941s-24.02723 7.776104-28.614711 19.579941L375.458719 360.749204l-282.708803 21.915126c-12.276604 0.951675-22.796192 9.146311-26.723641 20.816095-3.927449 11.669784-0.50142 24.557302 8.701173 32.737612l217.547735 193.358823-67.980277 291.298436c-2.848884 12.20702 2.009773 24.919553 12.273535 32.114418 10.264784 7.195889 23.87271 7.42818 34.375925 0.586354l240.624313-156.709111 240.625336 156.709111c5.099135 3.320629 10.92891 4.974291 16.752546 4.974291 6.173606 0 12.342096-1.858324 17.623379-5.561669 10.263761-7.194866 15.122419-19.907399 12.273535-32.114418l-67.980277-291.299459L948.409906 436.218036C957.613522 428.037726 961.037505 415.150208 957.111079 403.480424z" fill="#F8AE00" p-id="4050"></path></svg></div>
                </div>
              </div>
            </div>

          </div>

          <!-- 附件 -->
          <div v-if="type == 'file' " class="enclosure">

            <div class="file"  v-for="(item,index) in dataLiat"  :key="index">

              <div class="folderImgAndName">
                <img class="folderImg" src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/menu/hongbao/img1.png" >
                <img v-if="item.PayStatus == 1 && item.paymentType == 2 " class="folder_img_url" src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/menu/hongbao/%E5%B7%B2%E8%B4%AD.png">
                <img v-else-if="item.PayStatus == 0 && item.paymentType == 2 " class="folder_img_url" src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/menu/hongbao/%E4%BB%98%E8%B4%B9.png">
                <img v-else-if="item.PayStatus == 0 && item.paymentType == 0" class="folder_img_url" src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/menu/hongbao/%E5%85%8D%E8%B4%B9.png">
                <div class="folderName" >{{item.title}}</div>
                <div v-if="item.isCollection == 0" >
                  <div style="float: right;color: #666666;cursor: pointer;margin:10px 15px 0 5px;">收藏</div>
                  <div style="float: right;margin-top: 10px "> <svg t="1669371301358" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2679" width="20" height="20"><path d="M512 720.416l232.096 171.904a19.2 19.2 0 0 0 16.864 2.88c9.536-2.88 14.592-12.224 11.84-20.864l-88.96-281.6 237.344-176.96a16.48 16.48 0 0 0 6.816-13.152c0-9.152-7.904-16.928-18.112-16.96l-292-0.448-88.48-277.12a17.568 17.568 0 0 0-11.776-11.264c-9.856-2.976-20.16 2.304-23.04 11.264l-88.48 277.12-292 0.48a18.624 18.624 0 0 0-14.624 6.944 16.096 16.096 0 0 0 3.328 23.136l237.376 176.96-88.992 281.6a15.904 15.904 0 0 0 2.72 14.688c6.048 7.744 17.856 9.312 25.984 3.296L512 720.416z m-194.016 223.36a83.008 83.008 0 0 1-114.56-15.424 79.904 79.904 0 0 1-13.28-73.28l75.296-238.24-200.864-149.76a80.096 80.096 0 0 1-15.424-113.92 82.624 82.624 0 0 1 64.864-31.456l245.312-0.384 74.304-232.672c13.6-42.56 59.52-66.112 102.56-53.024A81.536 81.536 0 0 1 590.4 88.64l74.304 232.64 245.312 0.416c45.184 0.064 82.08 36.16 82.016 81.024a80.48 80.48 0 0 1-32.576 64.352l-200.864 149.76 75.296 238.24c13.568 42.976-11.072 88.352-54.496 101.408a83.2 83.2 0 0 1-73.344-12.736L512 800.064l-194.016 143.68z" p-id="2680" fill="#8a8a8a"></path></svg></div>
                </div>
                <div v-else>
                  <div style="float: right;color: #666666;cursor: pointer;margin:10px 15px 0 5px;">已收藏</div>
                  <div style="float: right;margin-top: 10px "> <svg t="1697536235932" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4049" width="20" height="20"><path d="M957.111079 403.480424c-3.927449-11.669784-14.447037-19.86442-26.723641-20.816095L647.68068 360.749204 540.183388 84.126003c-4.586458-11.803837-15.951297-19.579941-28.614711-19.579941s-24.02723 7.776104-28.614711 19.579941L375.458719 360.749204l-282.708803 21.915126c-12.276604 0.951675-22.796192 9.146311-26.723641 20.816095-3.927449 11.669784-0.50142 24.557302 8.701173 32.737612l217.547735 193.358823-67.980277 291.298436c-2.848884 12.20702 2.009773 24.919553 12.273535 32.114418 10.264784 7.195889 23.87271 7.42818 34.375925 0.586354l240.624313-156.709111 240.625336 156.709111c5.099135 3.320629 10.92891 4.974291 16.752546 4.974291 6.173606 0 12.342096-1.858324 17.623379-5.561669 10.263761-7.194866 15.122419-19.907399 12.273535-32.114418l-67.980277-291.299459L948.409906 436.218036C957.613522 428.037726 961.037505 415.150208 957.111079 403.480424z" fill="#F8AE00" p-id="4050"></path></svg></div>
                </div>
              </div>

              <div class="fileList" v-if="item.showAttachments" >
                <div class="folder op sc hand" v-for="(i,idx) in item.enclosure" :key="idx">
                  <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/menu/hongbao/icon10.png" >
                  <div style="float: left;width: 230px;font-size: 14px;"><span>{{i['name']}}</span></div>
                  <img @click="download(i['url'],i['name'],item.id)" style="width: 20px;height: 20px;float: left;margin: 14px" src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/menu/hongbao/%E4%B8%8B%E8%BD%BD.png">
                </div>
              </div>

              <div class="folder_border"></div>

              <div class="folder_more op sc hand" @click="showFile(index)">
                  <i :class="item.showAttachments ?'el-icon-caret-top':'el-icon-caret-bottom'"></i>
                  {{ item.showAttachments ? '收回附件' : '查看附件' }}
              </div>

            </div>

          </div>

          <!-- 文章 -->
          <div class="article" v-if="type == 'content' " v-for="(item,index) in dataLiat" :key="index" >

            <img :src="item.cover +'-200x200'" >

            <div class="contentMain">
              <div class="title">{{item.title}}</div>
              <div class="content">{{item.contentText}}</div>
              <div style="display:flex;justify-content: space-between;height: 30px;line-height: 30px;">
                <div>
                  <span style="color: #999999;">{{ item.createTime }}</span>
                  <span style="margin-left: 20px;color: #031F88;">{{ item.typeNamelist[0] }}>{{ item.typeNamelist[1] }}>{{ item.typeNamelist[2] }}</span>
                </div>
                <div v-if="item.isCollection == 0" style="display: flex;align-items: center" >
                  <svg t="1669371301358" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2679" width="20" height="20"><path d="M512 720.416l232.096 171.904a19.2 19.2 0 0 0 16.864 2.88c9.536-2.88 14.592-12.224 11.84-20.864l-88.96-281.6 237.344-176.96a16.48 16.48 0 0 0 6.816-13.152c0-9.152-7.904-16.928-18.112-16.96l-292-0.448-88.48-277.12a17.568 17.568 0 0 0-11.776-11.264c-9.856-2.976-20.16 2.304-23.04 11.264l-88.48 277.12-292 0.48a18.624 18.624 0 0 0-14.624 6.944 16.096 16.096 0 0 0 3.328 23.136l237.376 176.96-88.992 281.6a15.904 15.904 0 0 0 2.72 14.688c6.048 7.744 17.856 9.312 25.984 3.296L512 720.416z m-194.016 223.36a83.008 83.008 0 0 1-114.56-15.424 79.904 79.904 0 0 1-13.28-73.28l75.296-238.24-200.864-149.76a80.096 80.096 0 0 1-15.424-113.92 82.624 82.624 0 0 1 64.864-31.456l245.312-0.384 74.304-232.672c13.6-42.56 59.52-66.112 102.56-53.024A81.536 81.536 0 0 1 590.4 88.64l74.304 232.64 245.312 0.416c45.184 0.064 82.08 36.16 82.016 81.024a80.48 80.48 0 0 1-32.576 64.352l-200.864 149.76 75.296 238.24c13.568 42.976-11.072 88.352-54.496 101.408a83.2 83.2 0 0 1-73.344-12.736L512 800.064l-194.016 143.68z" p-id="2680" fill="#8a8a8a"></path></svg>
                  <div style="color: #666666;cursor: pointer;margin-left: 10px;">收藏</div>
                </div>
                <div v-else style="display: flex;align-items: center">
                  <svg t="1697536235932" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4049" width="20" height="20"><path d="M957.111079 403.480424c-3.927449-11.669784-14.447037-19.86442-26.723641-20.816095L647.68068 360.749204 540.183388 84.126003c-4.586458-11.803837-15.951297-19.579941-28.614711-19.579941s-24.02723 7.776104-28.614711 19.579941L375.458719 360.749204l-282.708803 21.915126c-12.276604 0.951675-22.796192 9.146311-26.723641 20.816095-3.927449 11.669784-0.50142 24.557302 8.701173 32.737612l217.547735 193.358823-67.980277 291.298436c-2.848884 12.20702 2.009773 24.919553 12.273535 32.114418 10.264784 7.195889 23.87271 7.42818 34.375925 0.586354l240.624313-156.709111 240.625336 156.709111c5.099135 3.320629 10.92891 4.974291 16.752546 4.974291 6.173606 0 12.342096-1.858324 17.623379-5.561669 10.263761-7.194866 15.122419-19.907399 12.273535-32.114418l-67.980277-291.299459L948.409906 436.218036C957.613522 428.037726 961.037505 415.150208 957.111079 403.480424z" fill="#F8AE00" p-id="4050"></path></svg>
                  <div style="color: #666666;cursor: pointer;margin-left: 10px;">已收藏</div>
                </div>

              </div>
            </div>


          </div>


        </div>

        <!-- question -->
        <div v-if="activeName == 'question' " style="background: #ffffff">
          <div v-for="(item, index) in dataLiat" :key="index">
            <div class="box hand op" @click="toQuestionInfo(item.id)" style="padding:0 20px;">
              <div style=" display: flex; justify-content: space-between; line-height: 56px">
                <div style="display: flex; width: 92%">
                  <div style="margin-right: 10px" v-if="item.isReward === 1">
                    <img src="./imgs/paper.png" style="width: 16px; height: 18px; margin-right: 8px;vertical-align: middle">
                    <span style="color: #FF5E5E;">{{item.rewardMoney}}元</span>
                  </div>

                  <div>
                    <span style="font-size: 14px; color: #999999; border: 1px #999999 solid; padding: 2px 4px; margin-right: 10px; background-color: #FFFFFF">{{item.TypeArrNameList[0]}} > {{item.TypeArrNameList[1]}} > {{item.TypeArrNameList[2]}}</span>
                    <span v-if="item.rewardType === 1" class="red-tag" style="color: #FF5E5E; border: 1px #FF5E5E solid;">红包问答</span>
                    <span v-if="item.rewardType === 2" class="red-tag" style="color: #F88450; border: 1px #F88450 solid;">悬赏资料</span>
                    <span v-if="item.rewardType === 3" class="red-tag" style="color: #00805E; border: 1px #00805E solid;">悬赏讨论</span>
                    <span v-if="item.rewardType === 4" class="red-tag" style="color: #2970FF; border: 1px #2970FF solid;">比选竞标</span>
                  </div>
                </div>

              </div>
              <div style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden;font-size: 16px;color: #444444;height: 40px;line-height: 40px;font-weight: bold">
                {{item.title}}
              </div>
              <div style="padding-bottom: 20px;border-bottom: 1px solid #CCCCCC;">{{item.content}}</div>
              <div style="display: flex;justify-content: space-between;align-items: center">
                <div style="display: flex;justify-content: space-between;align-items: center;padding: 20px 0;">
                  <img :src="item.headIco" style="height: 26px;width: 26px;border-radius: 50%;"/>
                  <span style="margin-left:10px;">{{item.publisher}}</span>
                  <span style="color: #999999;padding:0 20px; border-right:1px solid #CCCCCC;">{{item.reviewCount}}个回答</span>
                  <span style="color: #999999;padding:0 20px; border-right:1px solid #CCCCCC;">{{item.createTime}}</span>
                </div>
                <div style="width: 118px;height: 36px;border-radius: 0px 0px 0px 0px;border: 1px solid #2970FF;text-align: center;line-height: 40px;">我要回答</div>
              </div>
            </div>
          </div>
        </div>

        <!-- expert -->
        <div v-if="activeName == 'expert' " style="background: #ffffff">
          <div style="padding:20px 25px 10px 20px;display: flex;align-items: center;flex-wrap: wrap;">
            <div style="width: 280px;height: 350px;box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);border-radius: 5px;margin-bottom: 10px;padding: 20px 20px 0;box-sizing: border-box;margin-left:5px" v-for="item in dataLiat" :key="item.id">
              <div style="overflow: hidden">
                <div style="float: left;width: 60px;height: 60px;" class="hand op" @click="utils.a('/expert/info/'+ item.uuid )">
                  <img style="width: 45px;height: 45px;border-radius: 50%;margin-top: 7px;margin-left: 7px;"  :src="item.headIco +'-200x200'">
                </div>
                <div style="float: left;text-overflow: ellipsis; white-space: nowrap; overflow: hidden;width: 120px;height: 60px;" @click="utils.a('/expert/info/'+ item.uuid )">
                  <div style="font-weight: bold;margin-top: 10px"><span style="vertical-align: top; line-height: 20px">{{item.nickName}}</span> <img src="./imgs/expertIcon.png" style="width: 48px; height: 20px; margin-left: 2px"></div>
                  <div style="height: 22px;font-size: 12px; line-height: 20px; color: #c1c1c1;"><span v-if="item.address.cityName">{{item.address.cityName[1]}}</span><span v-if="item.major && item.address.cityName"> | </span>{{item.major}}</div>
                </div>
                <div class="hand op" @click="collect(item.uuid)" style="float: left;overflow: hidden; margin: 10px 0 0 6px; font-size: 14px; padding: 2px 10px; border: 1px #2970FF solid; color: #2970FF; border-radius: 20px">关注</div>
              </div>
              <div style="color: #999999; text-overflow:ellipsis;-webkit-line-clamp:4;-webkit-box-orient:vertical;overflow:hidden;display:-webkit-box;" class="hand op" @click="utils.a('/expert/info/'+ item.uuid )">
                专家简介：{{item.infos.expertAuth.introduction}}
              </div>
              <el-divider></el-divider>
              <div style="height: 64px;">
                <div style="display: flex;justify-content: space-between;align-items: center;margin-top: 10px;">
                  <div style="margin-left: 6px;color: #666666; width: 210px">
                    <div class="hand op" style="line-height: 30px;text-overflow: ellipsis; white-space: nowrap; overflow: hidden"
                         @click="utils.a('/expert/info/'+ item.uuid )"
                         v-for="ev in item.topics.slice(0,3)" :key="ev.id">#{{ ev.title }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- news -->
        <div v-if="activeName == 'news' " style="background: #ffffff">
          <div v-for="(item, index) in dataLiat" :key="index">
              <news :news="item"></news>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>

import ContentALL from "@/pages/room/components/ContentAll";
import News from "@/pages/search/components/news";
import circleContentALL from "@/pages/circle/components/ContentAll";

export default {
  components: {
    News,
    ContentALL,
    circleContentALL
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    is_login(){
      return this.$store.state.is_login
    },
    like(){
      return this.$store.state.like
    },

  },
  data () {
    return {

      more :false,

      typeList:[{name:"文章",value:"content"},{name:"视频",value:"video"},{name:"图片",value:"img"},{name:"附件",value:"file"}],

      dataLiat:[],

      page:1,

      activeName:"room",

      isload:false,

      content:"",

      type:"img",

      active_list:[{"label":"ROOM","name":"room"},{"label":"朋友圈","name":"circle"},{"label":"知识仓库","name":"knowledge"},{"label":"问答","name":"question"},{"label":"店铺","name":"shop"},{"label":"服务","name":"goods"},{"label":"案例","name":"case"},{"label":"信息","name":"news"},{"label":"专家","name":"expert"}],
    }
  },

  mounted:function(){
    var that = this;
    this.backTop();
    //that.fresh();

    // window.onscroll = function(){
    //   //变量scrollTop是滚动条滚动时，距离顶部的距离
    //   var scrollTop = document.documentElement.scrollTop||document.body.scrollTop;
    //
    //   //变量windowHeight是可视区的高度
    //   var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
    //   //变量scrollHeight是滚动条的总高度
    //   var scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;
    //   //滚动条到底部的条件
    //   var ratio = 0;
    //   var screen = window.screen;
    //   var ua = navigator.userAgent.toLowerCase();
    //   if (window.devicePixelRatio !== undefined) {
    //     ratio = window.devicePixelRatio;
    //   } else if (~ua.indexOf('msie')) {
    //     if (screen.deviceXDPI && screen.logicalXDPI) { //IE
    //       ratio = screen.deviceXDPI / screen.logicalXDPI;
    //     }
    //   } else if (window.outerWidth !== undefined && window.innerWidth !== undefined) {
    //     ratio = window.outerWidth / window.innerWidth;
    //   }
    //
    //   if(ratio <= 1){
    //     if(scrollTop+windowHeight >= scrollHeight){
    //
    //       that.fresh();
    //     }
    //   }else{
    //     if(scrollHeight - scrollTop-windowHeight  < 10){
    //
    //       that.fresh();
    //     }
    //   }
    // }

  },
  created:function(){
    var that = this;

    this.$EventBus.$on('search', type_name => {
      this.activeName = type_name;
    })
  },
  methods:{
    backTop () {
      document.documentElement.scrollTop = document.body.scrollTop = 0;
    },

    chooseType(value){
      this.type = value;
      this.clear();
      this.fresh();
    },

    showFile(index){
      this.$set(this.dataLiat[index],"showAttachments",!this.dataLiat[index].showAttachments);
    },
    clear(){

      this.dataLiat = [];
      this.page = 1;
      this.more = true;
    },

    fresh(){
      if(this.like == ''){
        this.$message.error("请输入搜索内容");
        return;
      }
      if(this.activeName == "room"){
        this.roomSearch();
      }
      if(this.activeName == "knowledge"){
        this.knowledgeSearch();
      }
      if(this.activeName == "circle"){
        this.circleSearch();
      }
      if(this.activeName == "question"){
        this.questionSearch();
      }
      if(this.activeName == "expert"){
        this.expertSearch();
      }
      if(this.activeName == "news"){
        this.newsSearch();
      }
    },
    inputChange(){
      this.$store.commit("setLike",this.content);
      this.clear();
      this.fresh();
    },
    roomSearch:function(){
      var that = this;
      if(!this.more){
        return;
      }
      this.newApi.roomSearch({
        content:this.like,
        pageNo:this.page,
        pageSize:"10"
      }).then(ret =>{
        for (let i = 0; i < ret.data.data.length; i++) {

          that.dataLiat.push(ret.data.data[i]);
        }
        if (ret.data.data.length < 10) {
          that.more = false;
        }
        that.page++;
      })
    },
    circleSearch:function(){
      var that = this;
      if(!this.more){
        return;
      }
      this.newApi.circleSearch({
        content:this.like,
        pageNo:this.page,
        pageSize:"10"
      }).then(ret =>{
        for (let i = 0; i < ret.data.data.length; i++) {

          that.dataLiat.push(ret.data.data[i]);
        }
        if (ret.data.data.length < 10) {
          that.more = false;
        }
        that.page++;
      })
    },

    questionSearch:function(){
      var that = this;
      if(!this.more){
        return;
      }
      this.newApi.questionSearch({
        content:this.like,
        pageNo:this.page,
        pageSize:"10"
      }).then(ret =>{
        for (let i = 0; i < ret.data.data.length; i++) {

          that.dataLiat.push(ret.data.data[i]);
        }
        if (ret.data.data.length < 10) {
          that.more = false;
        }
        that.page++;
      })
    },

    knowledgeSearch:function(){
      var that = this;
      if(!this.more){
       return;
      }

      this.newApi.knowledgeSearch({
        content:this.like,
        pageNo:this.page,
        pageSize:"10",
        type:this.type
      }).then(ret =>{
        for (let i = 0; i < ret.data.data.length; i++) {
          ret.data.data[i].showAttachments = false;
          that.dataLiat.push(ret.data.data[i]);
        }
        if (ret.data.data.length < 10) {
          that.more = false;
        }
        that.page++;
      })
    },
    expertSearch:function(){
      var that = this;
      if(!this.more){
        return;
      }
      this.newApi.getExpertList({
        page:this.page,
        pageSize:"10",
        like:this.like,
      }).then(ret => {
        for (let i = 0; i < ret.data.records.length; i++) {
          that.dataLiat.push(ret.data.records[i]);
        }
        if (ret.data.records.length < 10) {
          that.more = false;
        }
        that.page++;
      })
    },
    newsSearch:function(){
      var that = this;
      if(!this.more){
        return;
      }
      this.newApi.newsSearch({
        content:this.like,
        pageNo:this.page,
        pageSize:"10",
      }).then(ret =>{
        for (let i = 0; i < ret.data.data.length; i++) {
          that.dataLiat.push(ret.data.data[i]);
        }
        if (ret.data.data.length < 10) {
          that.more = false;
        }
        that.page++;
      })
    },

    handleClick(e){
      this.activeName = e;
      this.clear();
      this.fresh();
    },

    toQuestionInfo(id) {
      this.utils.b('/index/page/' + id)
    },

  }
}



</script>

<style scoped>

.el-menu{
  border: none
}
/deep/ .el-submenu__icon-arrow {
  display: none !important;
}
/deep/ .el-submenu__title {
  height: 42px !important;
  line-height: 42px !important;
}

.el-submenu__title:hover {
  background-color: #EEEEEE !important;
}
.el-menu-item:focus, .el-menu-item:hover {
  background-color: #EEEEEE !important;
}
.el-menu-item {
  min-width: 0 !important;
}

/deep/ .input-with-select .el-input-group__append {
  background-color: #2970FF;
  border: 1px solid #2970FF;
}

.el-input-group__append{
  background-color: #2970FF;
  color: #ffffff;
}
.el-input__inner{
  height: 45px;
}

.Fcti {
  width: 100%;
  background-color: #c2c2c266;
  color: #2970FF ;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 40px;
}

.enclosure{
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 20px;
  background: #FFFFFF;
}
.enclosure .file{
  width: 420px;
  height: auto;
  margin-bottom: 10px;
  background: #fff;
  box-shadow: 0 2px 8px 1px rgba(0,0,0,.16);
  padding-bottom: 10px;
  box-sizing: border-box;
}
.enclosure .file .folderImgAndName {
  overflow: auto;
  height: 40px;
  margin-top: 8.5px;
  padding-left: 20px;
}
.enclosure .file .folderImgAndName .folderImg{
  width: 26px;height: 24px; margin: 7.5px;
  float: left;
}
.enclosure .file .folderImgAndName .folder_img_url{
  width: 48px;
  height:20px;
  float: left;
  margin-top: 9.5px;
}
.enclosure .file .folderImgAndName .folderName{
  float: left;color: #555555;font-size:18px;text-overflow :ellipsis;
  white-space :nowrap;overflow : hidden;margin-left: 2.5px;
  height:40px;line-height: 40px;
  font-weight: 400;
  width: 150px;
}
.enclosure .file .fileList {
  margin: 0 10px;
}
.enclosure .file .fileList .folder {
  height: 48px;
  cursor: pointer;
  background: #f1f1f1;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.enclosure .file .fileList .folder img{
  width:22px;height:26px;
  margin: 11px 10px 11px 16px;
}
.enclosure .file .folder_border {
  width: 100%;
  border-top: 1px solid #CECFD0;
}
.enclosure .file .folder_more {
  clear: both;
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
  cursor: pointer
}

.article{
  padding: 10px 20px;
  background: #FFFFFF;
  display: flex;
  justify-content: flex-start;
  box-shadow: 0 2px 8px 1px rgba(0,0,0,.16);

}

.article .contentMain{
  margin-left: 18px;
  display: flex;
  justify-content: flex-start;
  flex-direction:column;
}
.article .contentMain .title{
  color: #444444;
  font-size: 18px;
  line-height: 30px;
  height: 30px;
  font-weight: bold;
  margin-bottom: 10px;
}
.article .contentMain .content{
  height: 120px;
  overflow: hidden;
  margin-bottom: 10px;
  color: #999999;
}
</style>
