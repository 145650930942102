<template>
  <div class="box hand op" style="padding:0 20px;width: 100%;min-height: 100px;background: #ffffff">
    <div style=" display: flex; justify-content: flex-start;">
        <div style="margin-right: 10px;box-sizing: border-box" >
          <span v-if="news.type === 1" class="red-tag" style="color: #2970FF;padding: 5px; border: 1px #2970FF solid;">招标信息</span>
          <span v-if="news.type === 2" class="red-tag" style="color: #2970FF;padding: 5px; border: 1px #2970FF solid;">采购信息</span>
          <span v-if="news.type === 3" class="red-tag" style="color: #2970FF;padding: 5px; border: 1px #2970FF solid;">招募信息</span>
          <span v-if="news.type === 4" class="red-tag" style="color: #2970FF;padding: 5px; border: 1px #2970FF solid;">商务信息</span>
          <span v-if="news.type === 5" class="red-tag" style="color: #2970FF; padding: 5px;border: 1px #2970FF solid;">招商信息</span>
          <span v-if="news.type === 6" class="red-tag" style="color: #2970FF;padding: 5px; border: 1px #2970FF solid;">需求信息</span>
        <div>
<!--          <span style="font-size: 14px; color: #999999; border: 1px #999999 solid; padding: 2px 4px; margin-right: 10px; background-color: #FFFFFF">{{news.TypeArrNameList[0]}} > {{news.TypeArrNameList[1]}} > {{news.TypeArrNameList[2]}}</span>-->

        </div>
      </div>
    </div>

    <div style="font-weight: bold;text-overflow: ellipsis; white-space: nowrap; overflow: hidden;font-size: 16px;">
      {{news.title}}
    </div>

    <div style="margin-bottom: 15px;font-size: 16px;font-weight: bold;">
      <span>联系人: </span>
      <span style="color:#2970FF">{{news.contacts}}</span>
    </div>

    <div style="margin-bottom: 15px;font-size: 16px;font-weight: bold;">
      <span>联系电话: </span>
      <span style="color:#2970FF">{{news.phone}}</span>
    </div>

    <div style="margin-bottom: 15px;font-size: 16px;font-weight: bold;">
      <span>联系地址: </span>
      <span style="color:#2970FF">{{news.projectAddress}}</span>
    </div>

    <div style="margin-bottom: 15px;font-size: 16px;font-weight: bold;">
      <span>截止时间: </span>
      <span style="color:#2970FF">{{news.endTime}}</span>
    </div>

    <div style="padding-bottom: 20px;border-bottom: 1px solid #CCCCCC;">{{news.content}}</div>


    <div style="display: flex;justify-content: space-between;align-items: center">
      <div style="display: flex;justify-content: space-between;align-items: center;padding: 20px 0;">
        <img :src="news.headIco" style="height: 26px;width: 26px;border-radius: 50%;"/>
        <span style="margin-left:10px;">{{news.nickName}}</span>
        <span style="color: #999999;padding:0 20px; border-right:1px solid #CCCCCC;">{{news.createTime}}</span>
      </div>
      <div style="width: 118px;height: 36px;border-radius: 0px 0px 0px 0px;border: 1px solid #2970FF;text-align: center;line-height: 40px;">我要报名</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "news",
  data() {
    return {
    }
  },
  props: {
    news: {
      type: Object,
      default() {
        return {
          nickName:"",
          headIco:"",
          createTime:"",
          endTime:"",
          content:"",
          title:"",
          projectAddress:"",
          contacts:"",
          phone:""
        };
      }
    },
  },
  mounted:function(){

  },
  methods:{}
}
</script>

<style scoped>

</style>
